import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
  type CanActivate,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { getParamsObject } from '@cosmos/core';
import { assertDefined } from '@cosmos/util-common';
import {
  CustomerPortalAuthActions,
  CustomerPortalAuthQueries,
} from '@customer-portal/auth/data-access-auth';

import { CustomerPortalLegacyUrlHandler } from './legacy-url-handler';

const presentationsUrl = '/presentations';

@Injectable({ providedIn: 'root' })
export class CustomerPortalAccessGuard implements CanActivate {
  private readonly _document = inject(DOCUMENT);

  constructor(
    private readonly _store: Store,
    private readonly _router: Router,
    private readonly _legacyUrlHandler: CustomerPortalLegacyUrlHandler
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<UrlTree | boolean> {
    const path = this._document.location.pathname;
    const params = this._getParams(route)!;

    !global_isRealProduction &&
      assertDefined(
        params,
        'CustomerPortalAccessGuard: params must be defined'
      );

    // This means we're dealing with legacy URL which starts with `/presentations`.
    // We're able to authenticate the user with `access_token` and `presentationId`
    // and then redirect him to `/projects` URL.
    // TODO(portal-url-migration): remove once we don't support legacy presentations URL.
    if (path.startsWith(presentationsUrl)) {
      // This should never resolve since we'll change the `location.href` directly.
      return new Promise(() =>
        this._legacyUrlHandler.handle(
          state,
          params.token!,
          params.accessCode!,
          params.presentationId!,
          params.settings
        )
      );
    }

    const session = this._store.selectSnapshot(
      CustomerPortalAuthQueries.getSession
    );
    // If `session` exists then the user has been already authenticated and we should just
    // allow bypassing this guard.
    // The `!params.token && !params.accessCode` condition is used to ensure the page is not previewed by distributor.
    // Suppose the distributor was logged in with token A, but this token has been expired, and
    // now the distributor has token B. The `getSession` will still return token A, because it was
    // persisted into the session storage.
    if (session && !params.token && !params.accessCode) {
      return true;
    }

    if (params.accessCode || params.token) {
      await firstValueFrom(
        this._store.dispatch(
          params.accessCode
            ? new CustomerPortalAuthActions.LoginWithAccessCode({
                accessCode: params.accessCode,
                projectId: params.projectId,
              })
            : new CustomerPortalAuthActions.LoginSuccess({
                access_token: params.token,
              })
        )
      );

      return this.getUrlTreeWithoutTokens(this._router, state);
    }

    return this._router.createUrlTree(['not-found']);
  }

  private _getParams(route: ActivatedRouteSnapshot) {
    const params = getParamsObject(route.paramMap, {
      optional: ['projectId', 'presentationId'],
    });

    const queryParams = getParamsObject(route.queryParamMap, {
      optional: ['accessCode', 'settings', 'token', 'postPayment'],
    });

    return (
      params &&
      queryParams && {
        projectId: params.projectId && parseInt(params.projectId, 10),
        presentationId:
          params.presentationId && parseInt(params.presentationId, 10),
        accessCode: queryParams.accessCode,
        settings: queryParams.settings,
        token: queryParams.postPayment ? undefined : queryParams.token,
      }
    );
  }

  getUrlTreeWithoutTokens(router: Router, state: RouterStateSnapshot): UrlTree {
    const urlTree = router.parseUrl(state.url);
    delete urlTree.queryParams['accessCode'];
    delete urlTree.queryParams['token'];
    return urlTree;
  }
}
