import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { ToastActions } from '@cosmos/types-notification-and-toast';
import { LanguageScope } from '@cosmos/util-translations';
import { CustomerPortalAuthActions } from '@customer-portal/auth/data-access-auth';
import {
  CustomerPortalPresentationActions,
  CustomerPortalPresentationQueries,
} from '@customer-portal/presentations/data-access-presentations';

// TODO(portal-url-migration): remove once we don't support legacy presentations URL.
@Injectable({ providedIn: 'root' })
export class CustomerPortalLegacyUrlHandler {
  private readonly _document = inject(DOCUMENT);

  constructor(
    private readonly _router: Router,
    private readonly _store: Store
  ) {}

  async handle(
    state: RouterStateSnapshot,
    token: string,
    accessCode: string,
    presentationId: number | string,
    settings: string | undefined
  ) {
    const translationKeyPrefix =
      'customerPortalAuth.data-access-customer-portal-access-guard.legacy-url-handler';
    // There must be token defined on params either access code.
    // Otherwise, we can't proceed with authentication.
    if (!token && !accessCode) {
      this._store.dispatch(
        new ToastActions.Show({
          title: `${translationKeyPrefix}.missing-access-code-error-title`,
          body: `${translationKeyPrefix}.missing-access-code-error-body`,
          type: 'error',
          languageScope: LanguageScope.CustomerPortalAuth,
        })
      );
      // Prevent any other code from being executed, the routing should go into a failed state.
      throw new Error(
        'There is no token or access code defined on URL params.'
      );
    }

    this._store.dispatch(
      new ToastActions.Show({
        title: '',
        body: `${translationKeyPrefix}.url-redirection-body`,
        type: 'confirm',
        languageScope: LanguageScope.CustomerPortalAuth,
      })
    );

    await firstValueFrom(
      this._store.dispatch(
        token
          ? new CustomerPortalAuthActions.LoginSuccess({
              access_token: token,
            })
          : // We shouldn't be checking if the user is already logged in since we're storing the
            // session token in `sessionStorage` which is cleaned up when the user leaves the page,
            // no matter if the page is bookmarked or not.
            new CustomerPortalAuthActions.LoginWithAccessCode({
              accessCode,
              presentationId,
            })
      )
    );

    // Load presentation since we don't know the associated project yet.
    await firstValueFrom(
      this._store.dispatch(
        // There's no reason to load quote since we'll redirect the user.
        new CustomerPortalPresentationActions.GetPresentation(
          presentationId,
          /* shouldLoadQuote */ false
        )
      )
    );

    const presentation = this._store.selectSnapshot(
      CustomerPortalPresentationQueries.getPresentation
    )!;

    const currentUrlTree = this._router.parseUrl(state.url);
    currentUrlTree.queryParams = {};

    const url = new URL(this._document.location.href);
    // `toString()` will give us a string with a slash at the beginning, for instance:
    // `/presentations/500000341/products`.
    // `slice(1)` is used to remove the slash so it becomes `presentations/500000341/products`.
    url.pathname = `/projects/${presentation.ProjectId}/${currentUrlTree
      .toString()
      .slice(1)}`;

    // We don't need token and access code anymore.
    url.searchParams.delete('token');
    url.searchParams.delete('accessCode');

    if (settings) {
      url.searchParams.set('settings', settings);
    }

    this._document.location.href = url.toString();
  }
}
